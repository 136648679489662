
import axios from 'axios';
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IntegrationType } from '@/entities/integrationType';
import { validateCreateIntegrationData } from '@/services/validation/actions';
import { createIntegration } from '@/services/steamcord/actions';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    if (!to.query.code) {
      next();
      return;
    }

    try {
      await createIntegration(currentOrg.id, '', IntegrationType.Discord, to.query.code.toString());
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) {
        next((vm) => {
          (vm as any).setFormError('You can only create one Discord integration.');
        });

        return;
      }

      throw err;
    }
    next({ name: 'Integrations', params: { subdomain: to.params.subdomain } });
  },
  computed: {
    discordGuildValue() {
      return IntegrationType.Discord;
    },
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, { appLoading: 'loading' }),
  },
  data() {
    return {
      createIntegrationErrors: {
        nameError: '',
        typeError: '',
        hasErrors: false,
      },
      formError: '',
      loading: false,
      name: '',
      type: -1 as IntegrationType | -1,
    };
  },
  methods: {
    async onSubmit() {
      if (this.loading || !this.currentOrg) {
        return;
      }

      if (this.type === IntegrationType.Discord) {
        window.location.href = `https://discord.com/api/oauth2/authorize?client_id=899026320623804486&permissions=268435457&redirect_uri=https%3A%2F%2Fsteamcord.io%2Fdashboard%2Fintegrations%2Fnew&response_type=code&scope=identify%20bot%20applications.commands&state=${this.$route.params.subdomain}`;
      }

      this.createIntegrationErrors = validateCreateIntegrationData(this.name, this.type);
      if (this.createIntegrationErrors.hasErrors || !this.type) {
        return;
      }

      this.loading = true;
      await createIntegration(this.currentOrg.id, this.name, this.type);

      this.$router.push({ name: 'Integrations', params: { subdomain: this.$route.params.subdomain } });
    },
    setFormError(err: string) {
      this.formError = err;
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
