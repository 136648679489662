import axios from 'axios';
import { IAction } from '../../entities/action';
import { IIntegration } from '../../entities/integration';
import { IntegrationType } from '@/entities/integrationType';

export function createIntegration(
  orgID: number, name: string, type: IntegrationType, code = '',
): Promise<void> {
  return axios.post(`/api/integrations?orgID=${orgID}`, {
    name,
    type,
    code,
  });
}

export async function getIntegrations(orgID: number): Promise<Partial<IIntegration>[]> {
  const { data: integrations } = await axios.get<IIntegration[]>(
    `/api/integrations?orgID=${orgID}`,
  );

  return integrations;
}

export async function getIntegration(
  orgID: number, integrationID: string,
): Promise<IIntegration | undefined> {
  try {
    const { data: integration } = await axios.get<IIntegration>(
      `/api/integrations/${integrationID}?orgID=${orgID}`,
    );

    return integration;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return undefined;
    }

    throw err;
  }
}

export function createAction(orgID: number, integrationID: number, action: IAction): Promise<void> {
  return axios.post(`/api/integrations/${integrationID}/actions?orgID=${orgID}`, action);
}

export async function getAction(orgID: number, actionID: string): Promise<IAction | undefined> {
  try {
    const { data: action } = await axios.get<IAction>(
      `/api/actions/${actionID}?orgID=${orgID}`,
    );

    return action;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return undefined;
    }

    throw err;
  }
}

export async function getActions(
  orgID: number, integrationID: string,
): Promise<IAction[] | undefined> {
  try {
    const { data: actions } = await axios.get(
      `/api/integrations/${integrationID}/actions?orgID=${orgID}`,
    );

    return actions;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return undefined;
    }

    throw err;
  }
}

export function updateAction(orgID: number, actionID: number, action: IAction): Promise<void> {
  return axios.put(`/api/actions/${actionID}?orgID=${orgID}`, action);
}

export function deleteAction(orgID: number, actionID: number): Promise<void> {
  return axios.delete(`/api/actions/${actionID}?orgID=${orgID}`);
}
