import { number, ValidationError } from 'yup';
import { IErrorAggregate } from './errorAggregate';
import { requiredStringSchema, validate, validateRequired } from '.';
import { IntegrationType } from '@/entities/integrationType';

const nameFormatMessage = 'Integration names must be no more than 32 characters.';
const typeFormatMessage = 'This is a required field.';

const validationSchema = {
  name: requiredStringSchema.max(32, nameFormatMessage),
  type: number().min(0, typeFormatMessage),
};

export interface ICreateIntegrationErrors extends IErrorAggregate {
  nameError: string;
  typeError: string;
}

function validateNumber(value: number | undefined) {
  try {
    validationSchema.type.validateSync(value);
    return '';
  } catch (err) {
    return (err as ValidationError).message;
  }
}

export function validateCreateIntegrationData(
  name: string, type: IntegrationType | undefined,
): ICreateIntegrationErrors {
  const errors = {
    nameError: validate(validationSchema.name, name),
    typeError: validateNumber(type),
    hasErrors: false,
  };

  errors.hasErrors = !!(errors.nameError || errors.typeError);

  return errors;
}

export interface ICreateActionErrors extends IErrorAggregate {
  nameError: string;
  whenToRunError: string;
  definitionNameError: string;
  argumentErrors: { [key: string]: string };
}

export function validateCreateActionData(
  name: string, whenToRun: number, definitionName: string, args: { [key: string]: string },
): ICreateActionErrors {
  const errors = {
    nameError: validate(validationSchema.name, name),
    whenToRunError: validateNumber(whenToRun),
    definitionNameError: validateRequired(definitionName),
    argumentErrors: {} as { [key: string]: string },
    hasErrors: false,
  } as ICreateActionErrors;

  errors.hasErrors = !!(errors.nameError || errors.whenToRunError || errors.definitionNameError);

  Object.keys(args).forEach((key) => {
    const error = validateRequired(args[key]);
    if (error) {
      errors.argumentErrors[key] = error;
      errors.hasErrors = true;
    }
  });

  return errors;
}
